<template>
  <div class="tw-container border border-1 border-dark p-3">
    <!-- 標題 -->
    <p class="tw-border-start fw-bolder tw-text-size20">結單優惠</p>
    <hr />
    <div class="mb-3">
      <button
        class="tw-btn tw-btn-success mb-3"
        @click="showModal('createPromotions')"
      >
        新增優惠
      </button>
      <p class="ps-2 mb-2" style="text-indent: -10px">
        * 顧客自行結單若要套用結單促銷優惠，請至「賣場設定 >
        結單/出貨/運送/付款」，將開放顧客結單使用優惠設定為<span
          class="fw-bolder"
          >允許顯示促銷活動。</span
        >
      </p>
      <p class="ps-2 mb-2">
        優惠將會於結單時進行計算（例如：滿千九折價、買千送百）
      </p>
      <p class="ps-2">
        如果同時有多筆優惠，結單時系統會自動選擇最優惠的方案來進行計算
      </p>
    </div>
    <!-- 表格 -->
    <AreaLoading v-if="storeDiscounts.loading"></AreaLoading>
    <div
      class="table-responsive"
      style="height: calc(100vh - 267px); overflow-y: scroll"
      v-else-if="storeDiscounts.data.length > 0"
    >
      <table class="table table-hover">
        <thead class="float-header">
          <tr>
            <th style="min-width: 100px">促銷名稱</th>
            <th style="min-width: 100px">狀態</th>
            <th style="min-width: 100px">促銷內容</th>
            <th style="min-width: 100px">VIP/群組</th>
            <th style="min-width: 150px">促銷檔期</th>
            <th style="min-width: 165px">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in storeDiscounts.data" :key="data.id">
            <td>{{ data.name }}</td>
            <td>
              <p
                class="badge bg-warning"
                style="white-space: normal"
                v-if="new Date().getTime() > data.expirationTime"
              >
                已過期
              </p>
              <p
                class="badge bg-danger"
                style="white-space: normal"
                v-else-if="data.pause"
              >
                已暫停
              </p>
              <p class="badge tw-bg-success" style="white-space: normal" v-else>
                進行中
              </p>
            </td>
            <td>
              <!-- 滿額折抵 -->
              <div
                v-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_AMOUNT_REBATE'
                "
              >
                <p>
                  滿{{
                    data.storeDiscountConditions[0].threshold
                  }}元，總金額折抵{{
                    data.storeDiscountConditions[0].discount
                  }}元<span v-if="data.storeDiscountConditions[0].progressive"
                    >，採累進折抵</span
                  >
                </p>
                <p v-if="data.storeDiscountConditions[0].progressive">
                  (滿{{ data.storeDiscountConditions[0].threshold * 2 }}元折抵{{
                    data.storeDiscountConditions[0].discount * 2
                  }}元，以此類推)
                </p>
              </div>
              <!-- 滿額打折 -->
              <div
                v-else-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_AMOUNT_DISCOUNT'
                "
              >
                <p>
                  滿{{
                    data.storeDiscountConditions[0].threshold
                  }}元，總金額打{{ data.storeDiscountConditions[0].discount }}折
                </p>
              </div>
              <!-- 滿額免運 -->
              <div
                v-else-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_AMOUNT_REBATE_SHIPPING_COST'
                "
              >
                <p>
                  滿{{ data.storeDiscountConditions[0].threshold }}元，免運費
                </p>
              </div>
              <!-- 滿件免運 -->
              <div
                v-else-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_QUANTITY_REBATE_SHIPPING_COST'
                "
              >
                <p>
                  滿{{ data.storeDiscountConditions[0].threshold }}件，免運費
                </p>
              </div>
            </td>
            <td>
              <p v-if="!data.storeVip && data.customerGroups.length === 0">
                會員皆可使用
              </p>
              <template v-else>
                <!-- vip 限制 -->
                <div title="VIP限制" class="mb-2" v-if="data.storeVip">
                  <img class="icon me-2" src="@/assets/icon/crown1.png" />
                  <span> {{ data.storeVip.name }} 以上</span>
                </div>
                <!-- 顧客群組限制 -->
                <div title="顧客群組限制" v-if="data.customerGroups.length > 0">
                  <img class="icon me-2" src="@/assets/icon/users.png" />
                  <span
                    v-for="(customerGroup, key) in data.customerGroups"
                    :key="customerGroup.id"
                  >
                    {{ customerGroup.name }}
                    <span v-if="key !== data.customerGroups.length - 1">
                      、
                    </span>
                  </span>
                </div>
              </template>
            </td>
            <td>
              <p title="促銷開始時間">
                {{
                  $methods.moment(data.enableTime).format("YYYY-MM-DD HH:mm:ss")
                }}
              </p>
              <i class="bi bi-three-dots-vertical"></i>
              <p title="促銷結束時間">
                {{
                  $methods
                    .moment(data.expirationTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                }}
              </p>
            </td>
            <td>
              <div class="me-2 d-inline-block">
                <button
                  class="tw-btn tw-btn-warning"
                  v-if="!data.pause"
                  @click="setPause(data)"
                >
                  暫停
                </button>
                <button
                  class="tw-btn tw-btn-success"
                  v-else
                  @click="setPause(data)"
                >
                  啟用
                </button>
              </div>
              <button
                class="tw-btn tw-btn-danger"
                @click="showModal('delPromotions', data)"
              >
                刪除
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="text-danger fw-bolder" v-else>目前尚無設定任何促銷優惠!</p>
  </div>
  <!-- 新增促銷優惠活動 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="promotionsModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            新增促銷優惠活動
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 折扣名稱 -->
          <div class="mb-3">
            <label class="fw-bolder ms-1 mb-2 text-danger"
              >*折扣名稱(15字)</label
            >
            <input
              type="text"
              class="form-control rounded-3"
              placeholder="請輸入折扣名稱"
              maxlength="15"
              v-model="promotionsData.discount.name"
            />
          </div>
          <!-- 是否限制VIP -->
          <div class="mb-3">
            <label class="fw-bolder ms-1 mb-2">是否限制VIP</label>
            <AreaLoading v-if="storeVips.loading"></AreaLoading>
            <select
              class="form-select rounded-3"
              id="VipGrate"
              v-else
              v-model="promotionsData.discount.storeVipId"
            >
              <option value="">不限制</option>
              <option
                :value="data.id"
                v-for="data in storeVips.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
          </div>
          <!-- 促銷檔期 -->
          <div class="row mb-3 p-0">
            <!-- 促銷開始時間 -->
            <div class="col-xl p-1 mb-2">
              <label class="fw-bolder text-danger mb-2">*促銷開始時間</label>
              <Calendar
                :inline="true"
                :showTime="true"
                :showSeconds="true"
                style="width: 100%"
                v-model="promotionsData.discount.enableTime"
              />
            </div>
            <!-- 促銷結束時間 -->
            <div class="col-xl p-1">
              <label class="fw-bolder text-danger mb-2">*促銷結束時間</label>
              <Calendar
                :inline="true"
                :showTime="true"
                :showSeconds="true"
                style="width: 100%"
                v-model="promotionsData.discount.expirationTime"
              />
            </div>
          </div>
          <!-- 折扣方式 -->
          <div class="row mb-3 p-0">
            <label class="fw-bolder mb-2 p-0">折扣方式</label>
            <!-- 選項 -->
            <div class="col-lg-2 mb-2">
              <!-- 滿額折抵 -->
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="FULL_AMOUNT_REBATE"
                  id="FULL_AMOUNT_REBATE"
                  name="promotionsMethod"
                  v-model="discountType"
                />
                <label class="form-check-label" for="FULL_AMOUNT_REBATE">
                  滿額折抵
                </label>
              </div>
              <!-- 滿額打折 -->
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="FULL_AMOUNT_DISCOUNT"
                  id="FULL_AMOUNT_DISCOUNT"
                  name="promotionsMethod"
                  v-model="discountType"
                />
                <label class="form-check-label" for="FULL_AMOUNT_DISCOUNT">
                  滿額打折
                </label>
              </div>
              <!-- 滿額免運 -->
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="FULL_AMOUNT_REBATE_SHIPPING_COST"
                  id="FULL_AMOUNT_REBATE_SHIPPING_COST"
                  name="promotionsMethod"
                  v-model="discountType"
                />
                <label
                  class="form-check-label"
                  for="FULL_AMOUNT_REBATE_SHIPPING_COST"
                >
                  滿額免運
                </label>
              </div>
              <!-- 滿件免運 -->
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="FULL_QUANTITY_REBATE_SHIPPING_COST"
                  id="FULL_QUANTITY_REBATE_SHIPPING_COST"
                  name="promotionsMethod"
                  v-model="discountType"
                />
                <label
                  class="form-check-label"
                  for="FULL_QUANTITY_REBATE_SHIPPING_COST"
                >
                  滿件免運
                </label>
              </div>
            </div>
            <!-- 內容 -->
            <div class="col-lg-10 p-0">
              <!-- 滿額折抵 -->
              <div
                class="tw-container border border-1 border-dark"
                v-if="discountType === 'FULL_AMOUNT_REBATE'"
              >
                <!-- 優惠門檻 -->
                <div class="mb-2 d-flex align-items-center">
                  <label style="min-width: 80px">優惠門檻</label>
                  <div class="input-group">
                    <input
                      onwheel="this.blur()"
                      type="number"
                      class="form-control rounded-start"
                      v-model="promotionsData.conditions[0].threshold"
                      @input="
                        promotionsData.conditions[0].threshold =
                          $methods.numberToFixed(
                            promotionsData.conditions[0].threshold
                          )
                      "
                    />
                    <span class="input-group-text bg-white">元</span>
                  </div>
                </div>
                <!-- 折扣金額 -->
                <div class="mb-2 d-flex align-items-center">
                  <label style="min-width: 80px">折扣金額</label>
                  <div class="input-group">
                    <input
                      onwheel="this.blur()"
                      type="number"
                      class="form-control rounded-start"
                      v-model="promotionsData.conditions[0].discount"
                      @input="
                        promotionsData.conditions[0].discount =
                          $methods.numberToFixed(
                            promotionsData.conditions[0].discount
                          )
                      "
                    />
                    <span class="input-group-text bg-white">元</span>
                  </div>
                </div>
                <!-- 累進優惠計算 -->
                <div class="text-end">
                  <div class="form-check form-switch p-0 d-inline-block">
                    <input
                      class="form-check-input m-0 me-2"
                      type="checkbox"
                      role="switch"
                      id="accumulate"
                      v-model="promotionsData.conditions[0].progressive"
                    />
                    <label class="form-check-label" for="accumulate"
                      >累進優惠計算</label
                    >
                  </div>
                </div>
              </div>
              <!-- 滿額打折 -->
              <div
                class="tw-container border border-1 border-dark"
                v-else-if="discountType === 'FULL_AMOUNT_DISCOUNT'"
              >
                <!-- 優惠門檻 -->
                <div class="mb-2 d-flex align-items-center">
                  <label style="min-width: 80px">優惠門檻</label>
                  <div class="input-group">
                    <input
                      onwheel="this.blur()"
                      type="number"
                      class="form-control rounded-start"
                      v-model="promotionsData.conditions[0].threshold"
                      @input="
                        promotionsData.conditions[0].threshold =
                          $methods.numberToFixed(
                            promotionsData.conditions[0].threshold
                          )
                      "
                    />
                    <span class="input-group-text bg-white">元</span>
                  </div>
                </div>
                <!-- 折扣金額 -->
                <div class="mb-2 d-flex align-items-center">
                  <label style="min-width: 80px">折扣金額</label>
                  <div class="input-group">
                    <input
                      onwheel="this.blur()"
                      type="number"
                      class="form-control rounded-start"
                      v-model="promotionsData.conditions[0].discount"
                      @input="
                        promotionsData.conditions[0].discount =
                          $methods.numberToFixed(
                            promotionsData.conditions[0].discount
                          )
                      "
                    />
                    <span class="input-group-text bg-white">折</span>
                  </div>
                </div>
              </div>
              <!-- 滿額免運 -->
              <div
                class="tw-container border border-1 border-dark"
                v-else-if="discountType === 'FULL_AMOUNT_REBATE_SHIPPING_COST'"
              >
                <!-- 優惠門檻 -->
                <div class="mb-2 d-flex align-items-center">
                  <label style="min-width: 80px">優惠門檻</label>
                  <div class="input-group">
                    <input
                      onwheel="this.blur()"
                      type="number"
                      class="form-control rounded-start"
                      v-model="promotionsData.conditions[0].threshold"
                      @input="
                        promotionsData.conditions[0].threshold =
                          $methods.numberToFixed(
                            promotionsData.conditions[0].threshold
                          )
                      "
                    />
                    <span class="input-group-text bg-white">元</span>
                  </div>
                </div>
              </div>
              <!-- 滿件免運 -->
              <div
                class="tw-container border border-1 border-dark"
                v-else-if="
                  discountType === 'FULL_QUANTITY_REBATE_SHIPPING_COST'
                "
              >
                <!-- 優惠門檻 -->
                <div class="mb-2 d-flex align-items-center">
                  <label style="min-width: 80px">優惠門檻</label>
                  <div class="input-group">
                    <input
                      onwheel="this.blur()"
                      type="number"
                      class="form-control rounded-start"
                      v-model="promotionsData.conditions[0].threshold"
                      @input="
                        promotionsData.conditions[0].threshold =
                          $methods.numberToFixed(
                            promotionsData.conditions[0].threshold
                          )
                      "
                    />
                    <span class="input-group-text bg-white">件</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 是否選擇客群 -->
          <div>
            <label class="fw-bolder mb-2">是否選擇客群</label>
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                v-model="limitCustomerGroups"
              />
              <span v-if="limitCustomerGroups">限制</span>
              <span v-else>不限制</span>
            </div>
            <!-- 顧客群組選項(複選) -->
            <div
              class="tw-container border border-1 border-dark"
              v-if="limitCustomerGroups"
            >
              <AreaLoading v-if="storeCustomerGroups.loading"></AreaLoading>
              <div
                class="form-check ms-2"
                v-for="storeCustomerGroup in storeCustomerGroups.data"
                :key="storeCustomerGroup.id"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="storeCustomerGroup.id"
                  :id="storeCustomerGroup.id"
                  v-model="promotionsData.customerGroupIds"
                />
                <label class="form-check-label" :for="storeCustomerGroup.id">
                  {{ storeCustomerGroup.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createPause"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除優惠 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delPromotionsModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除促銷優惠
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-danger fw-bolder">是否確定刪除?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-danger" @click="delPause">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Calendar
import Calendar from 'primevue/calendar';

export default {
  components: {
    Calendar
  },
  data() {
    return {
      // modal
      promotionsModal: {},
      delPromotionsModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // 賣場資訊
      storeInfo: {},
      // 賣場促銷優惠資料     
      storeDiscounts: {
        loading: false,
        data: []
      },
      // 賣場VIP資料
      storeVips: {
        loading: false,
        data: []
      },
      // 賣場群組資料
      storeCustomerGroups: {
        loading: false,
        data: []
      },
      // 新增促銷活動資料
      limitCustomerGroups: false,
      discountType: 'FULL_AMOUNT_REBATE',
      promotionsData: {
        discount: {
            storeId: this.storeId,
            siteObjectType: 'BILLHEADER',
            name: '',
            storeVipId: '',
            pause: false,
            enableTime: '',
            expirationTime: '',
            pause: '',
          },
        conditions: [{
          discountType: '',
          threshold: 0,
          calculationType: '',
          discount: 0,
          progressive: false
        }],
        customerGroupIds: [],
      },
      // 刪除促消優惠Id
      delPromotionsId: '',
    }
  },
  created() {
    this.initialization()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'promotionsModal','delPromotionsModal'
    ])
  },
  watch: {
    discountType : {
      handler(val, oldVal) {
        this.promotionsData.conditions[0].discount = 0
        this.promotionsData.conditions[0].threshold = 0
        this.promotionsData.conditions[0].progressive = false
      }
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'createPromotions') {
        this.limitCustomerGroups = false,
        this.discountType = 'FULL_AMOUNT_REBATE',
        this.promotionsData = {
          discount: {
            storeId: this.storeId,
            siteObjectType: 'BILLHEADER',
            name: '',
            storeVipId: '',
            pause: false,
            enableTime: '',
            expirationTime: '',
            pause: '',
          },
          conditions: [{
            discountType: '',
            threshold: 0,
            calculationType: '',
            discount: 0,
            progressive: false
          }],
          customerGroupIds: [],
        }
        this.promotionsModal.show()
      } else if (status === 'delPromotions') {
        this.delPromotionsId = item.id
        this.delPromotionsModal.show()
      }
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      this.storeDiscounts = {
        loading: true,
        data: []
      }
      this.storeVips = {
        loading: true,
        data: []
      }
      this.storeCustomerGroups = {
        loading: true,
        data: []
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getCustomerGroups,getStoreVips,getStoreDiscounts{getStoreVip,getStoreDiscountConditions,getStoreDiscountCustomerGroupLinks,getCustomerGroups}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // 賣場促銷優惠資料
            vm.storeDiscounts = {
              loading: false,
              data: storeInfo.storeDiscounts
            }
            // 賣場VIP資料
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            // 賣場群組資料
            vm.storeCustomerGroups = {
              loading: false,
              data: storeInfo.customerGroups
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // 新增結單優惠
    createPause() {
      const vm = this
      const createPauseApi = `${process.env.VUE_APP_API}/storeDiscount/create`
      const header = {
        authorization: this.serverToken
      }
      this.promotionsData.conditions[0].discountType = this.discountType
      const data = [JSON.parse(JSON.stringify(this.promotionsData))]
      // 滿額折抵 (FULL_AMOUNT_REBATE)
      if (data[0].conditions[0].discountType === 'FULL_AMOUNT_REBATE') {
        data[0].conditions[0].calculationType = 'SUBTRACT'
        if (data[0].conditions[0].discount === '' || data[0].conditions[0].threshold === '') return this.SweetAlert('other', '優惠門檻或折扣金額不能為空值')
        if (data[0].conditions[0].discount < 0 || data[0].conditions[0].threshold < 0) return this.SweetAlert('other', '優惠門檻或折扣金額不能為負數')
      }
      // 滿額打折 (FULL_AMOUNT_DISCOUNT)
      else if (data[0].conditions[0].discountType === 'FULL_AMOUNT_DISCOUNT') {
        data[0].conditions[0].calculationType = 'MULTIPLY'
        delete data[0].conditions[0].progressive
        if (data[0].conditions[0].discount === '' || data[0].conditions[0].threshold === '') return this.SweetAlert('other', '優惠門檻或折扣金額不能為空值')
        if (data[0].conditions[0].discount < 0 || data[0].conditions[0].threshold < 0 ) return this.SweetAlert('other', '優惠門檻或折扣金額不能為負數')
      }
      // 滿額免運 (FULL_AMOUNT_REBATE_SHIPPING_COST)
      else if (data[0].conditions[0].discountType === 'FULL_AMOUNT_REBATE_SHIPPING_COST') {
        data[0].conditions[0].calculationType = 'SUBTRACT'
        delete data[0].conditions[0].discount
        delete data[0].conditions[0].progressive
        if (data[0].conditions[0].threshold === '') return this.SweetAlert('other', '優惠門檻不能為空值')
        if (data[0].conditions[0].threshold < 0 ) return this.SweetAlert('other', '優惠門檻不能為負數')
      }
      // 滿件免運 (FULL_QUANTITY_REBATE_SHIPPING_COST)
      else if (data[0].conditions[0].discountType === 'FULL_QUANTITY_REBATE_SHIPPING_COST') {
        data[0].conditions[0].calculationType = 'SUBTRACT'
        delete data[0].conditions[0].discount
        delete data[0].conditions[0].progressive
        if (data[0].conditions[0].threshold === '') return this.SweetAlert('other', '優惠門檻不能為空值')
        if (data[0].conditions[0].threshold < 0 ) return this.SweetAlert('other', '優惠門檻不能為負數')
        
      }
      
      this.$methods.switchLoading('show')
      data[0].discount.enableTime = new Date(data[0].discount.enableTime).getTime()
      data[0].discount.expirationTime = new Date(data[0].discount.expirationTime).getTime()
      $.ajax({
        type: 'POST',
        async: true,
        url: createPauseApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo()
            vm.promotionsModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 暫停/啟用結單優惠
    setPause(promotionData) {
      const vm = this
      this.$methods.switchLoading('show')
      const setPauseApi = `${process.env.VUE_APP_API}/storeDiscount/setPause`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('ids', promotionData.id)
      data.append('pause', !promotionData.pause)
      $.ajax({
        type: 'PUT',
        async: true,
        url: setPauseApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除結單優惠
    delPause() {
      const vm = this
      this.$methods.switchLoading('show')
      const delPauseApi = `${process.env.VUE_APP_API}/storeDiscount/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('ids', [this.delPromotionsId])
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delPauseApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo()
            vm.delPromotionsModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  }
}
</script>